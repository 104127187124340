/* eslint-disable no-unused-vars */
export enum AppRoutes {
    Users = '/users',
    Roles = '/roles',
    Seller = '/sellers',
    Makes = '/admin/makes',
    City = '/admin/cities',
    Models = '/admin/models',
    CategoryCategories = '/category/categories',
    CategorySubCategories = '/category/sub-categories',
    CouponsPage = '/admin/coupons',
    Faq = '/documitra-content/faqs',
    Service = '/documitra-content/services',
    Advantage = '/documitra-content/advantages',
    HowItWorks = '/documitra-content/how-it-works',
    Reviews = '/documitra-content/reviews',
    PhotomitraAdvantage = '/photomitra/advantages',
    PhotomitraHowItWorks = '/photomitra/how-it-works',
    PhotomitraTakingPhotoStep = '/photomitra/taking-photo-steps',
    PhotoStorePhotSize = '/photo-store/photo-size',
    PhotoStoreFileType = '/photo-store/file-type',
    ContactInfo = '/contact/contact-info',
    ContactForm = '/contact/user-info',
    ApplicationFormServices = '/services',
    ApplicationFormParameters = '/parameters',
    ApplicationFormQuestion = '/questions',
    ApplicationFormPackageServiceAttributes = '/packages/service-attributes',
    ApplicationFormPackageDocuments = '/packages/documents',
    ApplicationFormPackageFeatures = '/packages/features',
    ApplicationFormPackagePlans = '/packages/plans',
    ApplicationFormPackages = '/packages',
    ServiceProcessSteps = '/service-process/process-steps',
    ServiceProcessDetailsProcess = '/service-process/process',
    ServiceProcessSubtitle = '/service-process/process/subtitle',
    AllOrderList = '/admin/orders',
    PickUpManagement = '/admin/third-parties',
    GarageManagement = '/admin/garages',
    GarageManagementApplication = '/admin/garages/application',
    UserList = '/admin/customers',
    Guidelines = '/admin/guidelines',
    PaymentListing = '/admin/payments',
    ThirdPartyCharges = '/admin/third-party-charges',
    TowingCharges = '/admin/towing-vehicle-charges',
    CommissionPage = '/admin/commissions',
    GarageCommissionPage = '/garage/service/garages',
    Dashboard = '/admin/dashboard',
    ServiceList = '/admin/services',
    ReferralCoupons = '/admin/referral-coupons',

    GarageCommission = '/admin/garage-commissions',
    // Remove After Final Work
    StoreStatus = '/store-status',
    Inventory = '/inventory',
    Categories = '/categories',
    Subcategories = '/subcategories',
    defectTypes = '/Defect-Types',
    complainStatus = '/complaint-status',
    locations = '/locations',
    complaintsPage = '/complaints',
    OrderList = '/orderList',
    managerTechnician = '/manager-technician',
    assets = '/assets',
    locationType = '/location-type',
}
const entityArray = [
    { key: 'item-1', entityName: 'Users', entityUrl: AppRoutes.Users },
    {
        key: 'item-2',
        entityName: 'Roles',
        entityUrl: AppRoutes.Roles,
    },
    { key: 'item-3', entityName: 'Seller', entityUrl: AppRoutes.Seller },
];
type ServiceTagsOption = { id: string; value: string; name: string }[];
export const serviceTagOption: ServiceTagsOption = [
    { id: '1', value: 'value-added-service', name: 'Value Added Service' },
    { id: '2', value: 'schedule-service', name: 'Schedule Service' },
    { id: '3', value: 'other', name: 'Other' },
];
export enum NextFrom {
    BASIC_DETAIL = 'basic-detail',
    BANK_DETAIL = 'bank-detail',
    MARKETING = 'marketing',
    STAFF = 'staff',
    SERVICE = 'service',
}
export enum GarageStatus {
    ACCEPTED = 'accepted',
    REJECTED = 'rejected',
    NEW = 'new',
    BLACKLISTED = 'blacklisted',
}

export enum PickupStatus {
    PICKUP_SCHEDULED = 'pickup-scheduled',
    VEHICLE_IN_TRANSIT = 'vehicle-in-transit',
    VEHICLE_REACHED = 'vehicle-reached',
}

export { entityArray };
