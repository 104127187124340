import { customErrorMsg, errorMsg } from '../constants/messages';
import { REGEX_PHONE_NUMBER } from '../utils/Regexp/Regex';

export const rolesOption = [
    { id: '1', value: 'Not started', name: 'Not started' },
    { id: '2', value: 'In progress', name: 'In progress' },
    { id: '3', value: 'Amazon access', name: 'Amazon access' },
    { id: '3', value: 'Complete', name: 'Complete' },
];

const sellerFormData = [
    {
        title: 'Seller Details',
        name: 'name',
        label: 'Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Name', enableSort: false, allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'state',
        label: 'State',
        fieldType: {
            name: 'select',
            options: rolesOption,
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'State' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'is_active',
        label: 'Is Seller Active',
        fieldType: {
            name: 'select',
            options: [
                { name: 'YES', value: true },
                { name: 'NO', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Is Seller Active' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'Username',
        label: 'User Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'User Name' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'email',
        label: 'E-mail',
        fieldType: {
            name: 'email',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'E-mail' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'phone',
        label: 'Phone Number',
        fieldType: {
            name: 'text',
        },
        required: true,
        rules: [{ pattern: REGEX_PHONE_NUMBER, message: customErrorMsg('phone number') }],
        errorMsg: errorMsg,
        tableMeta: { title: 'Phone Number', enableSort: false, allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'emailVerified',
        label: 'Is Email Verified',
        fieldType: {
            name: 'select',
            options: [
                { name: 'YES', value: true },
                { name: 'NO', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Is Email Verified' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'phoneVerified',
        label: 'Is Phone Verified',
        fieldType: {
            name: 'select',
            options: [
                { name: 'YES', value: true },
                { name: 'NO', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Is Phone Verified' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'userIsActive',
        label: 'Is User Active',
        fieldType: {
            name: 'select',
            options: [
                { name: 'YES', value: true },
                { name: 'NO', value: false },
            ],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Is User Active' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
];

export { sellerFormData };
