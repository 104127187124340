import { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Sidebar from '../../components/SideBar';
import { AppRoutes, entityArray } from '../../constants/AppConstant';
import UsersPage from '../UserPage';
import './styles.scss';
import Roles from '../Roles';
import Seller from '../Seller';

const Landing = () => {
    const [moduleList] = useState<any>(entityArray);

    return (
        <Router>
            <div className='landing-box'>
                <Sidebar title='xNeeti' entityArr={moduleList} />
                <div className='landing-box-content'>
                    <Switch>
                        <Route exact path={`${AppRoutes.Users}`}>
                            <UsersPage />
                        </Route>
                        <Route exact path={`${AppRoutes.Roles}`}>
                            <Roles />
                        </Route>
                        <Route exact path={`${AppRoutes.Seller}`}>
                            <Seller />
                        </Route>
                    </Switch>
                </div>
            </div>
        </Router>
    );
};

export default Landing;
