/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DatePicker, Switch, Table } from 'antd';
import moment from 'moment';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { DownOutlined, RightCircleOutlined } from '@ant-design/icons';

import { InputInterface, OnAddArguments } from '../../../utils/forms/FormInterface';
import useEffectExcludingMount from '../../../hooks/useEffectExcludingMount';
import allowTableShort from '../../../utils/TableShort';
import DefaultTableHeader from './DefaultTableHeader';
import callApi from '../../../Api/config';
import Dropdown from '../../../components/Dropdown';

import '../entity.scss';
import { AppRoutes, GarageStatus, NextFrom, PickupStatus } from '../../../constants/AppConstant';
import {
    PICK_UP_ENTITY,
    USERS_LIST_ENTITY,
    PAYMENT_ENTITY,
    SERVICE_LIST_ENTITY,
    GARAGE_COMMISSION_TYPE,
    USERS_ENTITY,
    ROLES_ENTITY,
    SELLER_ENTITY,
} from '../../../constants/EntityName';
import { ALL_ORDER_LIST_ENTITY } from '../../../constants/EntityName';
import { GARAGE_MANAGEMENT_ENTITY } from '../../../constants/EntityName';
import ConfirmationModal from '../../../components/ConfirmationModal';
import { toastSuccess } from '../../../service/Toast';
import { getFullName } from '../../../utils/functions';

type FilterList = any;

interface AbstractLandingProps {
    headerName: string;
    entityName: string;
    tableHeaders: Array<InputInterface>;
    refreshData?: boolean;
    onAdd?: (argument1?: OnAddArguments) => void;
    onSlotClick?: any;
    addName?: string;
    entityId?: string;
    code?: string;
    disableAdd?: boolean;
    disableSearch?: boolean;
    filtersList?: FilterList[];
    setFiltersList?: React.Dispatch<React.SetStateAction<FilterList[]>>;
    handleEdit?: (userData: any) => void;
    orderStatusQueryParams?: any;
    handleModal?: () => void;
    onSelect?: (arg: any) => any;
    paymentToggleStatus?: (status: any) => any | undefined;
    serviceToggleStatus?: (status: any) => any | undefined;
}

let previousPaginationCount = 0;

const validEntityNames = [
    `${ALL_ORDER_LIST_ENTITY}/requested`,
    `${ALL_ORDER_LIST_ENTITY}/active`,
    `${ALL_ORDER_LIST_ENTITY}/completed`,
    `${ALL_ORDER_LIST_ENTITY}/return`,
];

const AbstractLanding = (props: AbstractLandingProps) => {
    const { entityName, onSlotClick, disableSearch, filtersList, paymentToggleStatus, serviceToggleStatus } = props;
    const [apiListingData, setApiListingData] = useState<any>([] as any);

    const [loading, setLoading] = useState(false);

    const history = useHistory();

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });

    const [totalCount, setTotalCount] = useState<number>();
    const [searchQuery, setSearchQuery] = useState<string>();
    const [searchPhoneQuery, setPhoneSearchQuery] = useState<string>();
    const [searchCityQuery, setCitySearchQuery] = useState<string>();
    const [searchStateQuery, setStateSearchQuery] = useState<string>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [toggleId, setToggleId] = useState('');

    const [selectedFilter, setSelectedFilter] = useState<Array<{ key: string; value: CheckboxValueType[] }>>([]);

    const location = useLocation();
    const garageId = location.search.slice(1);

    const validEntities = [
        PICK_UP_ENTITY,
        `${PICK_UP_ENTITY}/pickup/${PickupStatus.PICKUP_SCHEDULED}`,
        `${PICK_UP_ENTITY}/pickup/${PickupStatus.VEHICLE_IN_TRANSIT}`,
        `${PICK_UP_ENTITY}/pickup/${PickupStatus.VEHICLE_REACHED}`,
    ];

    let modalText = (
        <h3>
            Are you sure you want to Update Status of <span style={{ fontWeight: 'bold' }}>Payment</span>?
        </h3>
    );

    let apiURL = '';
    const paymentURL = `${PAYMENT_ENTITY}/${toggleId}`;
    const serviceURL = `${SERVICE_LIST_ENTITY}/${toggleId}`;

    if (entityName === PAYMENT_ENTITY) {
        apiURL = paymentURL;
        modalText = (
            <h3>
                Are you sure you want to Update <span className='bold'>Payment Status</span>?
            </h3>
        );
    } else if (entityName === SERVICE_LIST_ENTITY) {
        apiURL = serviceURL;
        modalText = (
            <h3>
                Are you sure you want to Update <span className='bold'>Service Status</span>?
            </h3>
        );
    }

    const handleConfirm = async () => {
        try {
            const res = await callApi(apiURL, 'PATCH');

            if (res) {
                toastSuccess('Status Updated Successfully.');
                setShowModal(false);
                getData();
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error accepting garage:', error);
        }
    };

    const handleToggle = (toggleId: any) => {
        setShowModal(true);
        setToggleId(toggleId);
    };

    const closeModal = () => {
        setShowModal(false);
        getData();
    };

    const getData = async (paginationEvent = pagination) => {
        const queryParams: any = {};
        let res: any;

        if (props.entityId && props.code) {
            queryParams[props.code] = props.entityId;
        }

        if (paginationEvent) {
            const { current, pageSize } = paginationEvent;
            queryParams.page = current;
            queryParams.limit = pageSize;
        }

        if (searchQuery) {
            if (entityName === AppRoutes.OrderList) {
                queryParams.orderId = searchQuery;
            } else {
                queryParams.search = searchQuery;
            }
        }

        if (selectedFilter.length) {
            selectedFilter.forEach((v) => {
                queryParams[v.key] = v.value.join(',');
            });
        }

        setLoading(true);

        if (!queryParams.page || !queryParams.limit) {
            res = await callApi(props.entityName, 'GET');
        } else {
            res = await callApi(props.entityName, 'GET', null, queryParams);
        }

        if (res?.status) {
            const apiData = res?.data?.data?.users;
            const apiDataR = res?.data?.results;

            // const getTotalCount = () => {
            //     if ('hasMore' in apiData) {
            //         const totalCount = apiData.hasMore === true ? (+apiData.page + 1) * 10 : +apiData.page * 10;

            //         return totalCount;
            //     }

            //     return res?.data?.data.totalCount;
            // };

            // const totalCount = getTotalCount();
            setPaginationCount(res?.data?.totalCount);

            if (entityName === USERS_ENTITY) {
                res.data.results = apiData?.map((i: any) => ({
                    ...i,
                    roles: i?.roles[0]?.name,
                }));
                setApiListingData(res.data.results);
            }

            if (entityName === ROLES_ENTITY) {
                res.data.results = res?.data?.data?.results?.map((i: any) => ({
                    ...i,
                }));
                setApiListingData(res.data.results);
            }

            if (entityName === SELLER_ENTITY) {
                res.data.results = res?.data?.data?.map((i: any) => ({
                    ...i,
                    // eslint-disable-next-line camelcase
                    is_active: i?.is_active ? 'Yes' : 'No',
                    Username: i?.users[0]?.Username,
                    email: i?.users[0]?.email,
                    phone: i?.users[0]?.phone,
                    emailVerified: i?.users[0]?.emailVerified ? 'Yes' : 'No',
                    phoneVerified: i?.users[0]?.phoneVerified ? 'Yes' : 'No',
                    userIsActive: i?.users[0]?.userIsActive ? 'Yes' : 'No',
                }));

                setApiListingData(res.data.results);
            }

            // setApiListingData(res.data.results);
            setTotalCount(totalCount);
        }

        setLoading(false);
    };

    const setPaginationCount = (currentPaginationCount: number) => {
        if (currentPaginationCount === previousPaginationCount) {
            return;
        }
        previousPaginationCount = currentPaginationCount;
        setTotalCount(currentPaginationCount);
    };

    const onPageChange = async (event: any) => {
        setPagination(event);
        getData(event);
    };

    const onSummitFilter = (filterName: string, list: CheckboxValueType[]) => {
        const newFilter = selectedFilter.filter((value) => value.key !== filterName);

        if (list.length) {
            newFilter.push({
                key: filterName,
                value: list,
            });
        }
        setSelectedFilter(newFilter);
    };

    useEffectExcludingMount(() => {
        if (searchQuery !== undefined) {
            getData({ ...pagination, current: 1 });
        }
        if (searchPhoneQuery !== undefined) {
            getData({ ...pagination, current: 1 });
        }
        if (searchCityQuery !== undefined) {
            getData({ ...pagination, current: 1 });
        }
        if (searchStateQuery !== undefined) {
            getData({ ...pagination, current: 1 });
        }
    }, [searchQuery, searchPhoneQuery, searchCityQuery, searchStateQuery]);

    useEffectExcludingMount(() => {
        getData();
    }, [props.refreshData]);

    // THis is default api call for first time also
    useEffect(() => {
        getData({ ...pagination, current: 1 });
    }, [selectedFilter, entityName]);

    useEffect(() => {
        if (props.orderStatusQueryParams) {
            onSummitFilter('orderStatus', [props.orderStatusQueryParams]);
        }
    }, [props.orderStatusQueryParams]);

    const onAddClick = (record: any) => {
        if (props.onAdd) {
            props.onAdd(record);
        }
    };

    const getFilter = () => (
        <div className='flex'>
            {filtersList?.map((filter) => {
                switch (filter.filterType) {
                    case 'DatePicker':
                        return (
                            <DatePicker
                                key={filter.filterKey}
                                allowClear
                                suffixIcon={<DownOutlined />}
                                className='date-filter'
                                format='YYYY-MM-DD'
                                name={filter.filterName}
                                placeholder={filter.placeholder}
                                onChange={(_, dateString) => onSummitFilter(filter.key, [dateString])}
                            />
                        );
                    default:
                        return (
                            <Dropdown
                                checkBoxOptions={filter.options}
                                onSubmitFilter={onSummitFilter}
                                filterName={filter.filterName}
                                filterKey={filter.filterKey}
                                key={filter.filterKey}
                            />
                        );
                }
            })}
        </div>
    );

    const columns: any = props.tableHeaders.map((tableMeta: any) => ({
        key: tableMeta.tableKey,
        title: tableMeta.tableLabel,
        dataIndex: tableMeta.tableKey,
        // eslint-disable-next-line no-nested-ternary
        colSpan: tableMeta.type === 'image' ? 0 : tableMeta.type === 'combinedColumn' ? 2 : null,
        onCell: (record: any) => ({
            style: {
                cursor:
                    tableMeta.allowAddRecordClick ||
                    tableMeta.allowSlotRecordClick ||
                    tableMeta.allowAddRedirectOnClicks
                        ? 'pointer'
                        : 'default',
                textDecoration:
                    tableMeta.allowAddRecordClick ||
                    tableMeta.allowSlotRecordClick ||
                    tableMeta.allowAddRedirectOnClicks
                        ? 'underline'
                        : '',
            },
            onClick: () => {
                if (tableMeta.allowAddRecordClick) {
                    // This code may be used in future
                    // if (routesEntityArray.includes(entityName)) {
                    //     history.push(`${entityName}/${record?.id}`);
                    // } else {
                    onAddClick(record);
                    // }
                }

                // if (tableMeta.allowAddRedirectOnClicks) {
                //     history.push(`${ORDER_LIST_ENTITY}/${record.order.id}`);
                // }

                if (tableMeta.allowSlotRecordClick) {
                    const tableMetaUrl = tableMeta.url;

                    if (tableMetaUrl) {
                        history.push(`${entityName}/${record?.id}/${tableMetaUrl}`);
                    } else {
                        onSlotClick?.(tableMeta.tableKey, { id: record.id, name: record.name });
                    }
                }
            },
        }),

        sorter: allowTableShort(tableMeta.enableSort, tableMeta.tableKey),

        render: (value: any, record: any) => {
            if (tableMeta.columnType === 'userName') {
                return `${record.firstName} ${record.lastName}`;
            }

            if (tableMeta.columnType === 'orderIdHash') {
                return `${record.order.orderIdHash}`;
            }
            if (tableMeta.columnType === 'orderId') {
                return `${record.order.id}`;
            }

            if (tableMeta.columnType === 'countriesName') {
                // In that case the value will be undefined and hence the optional chaining
                return `${record?.countries?.join(', ') || '--'}`;
            }

            if (tableMeta.columnType === 'displayName') {
                // when Items are deleted then the corresponding inventories are not deleted. In that case the value will be undefined and hence the optional chaining
                return value?.name || '--';
            }

            if (tableMeta.columnType === 'servicesObjectArray') {
                return value
                    ?.map(
                        (services: { level: string; serviceDetail: string; serviceDetailLevel: string }) =>
                            `${services?.level} - ${services?.serviceDetail} - ${services?.serviceDetailLevel}`,
                    )
                    .join(', ');
            }

            if (tableMeta.columnType === 'facebookLink') {
                return `${record.socialMediaLinks.facebook} `;
            }
            if (tableMeta.columnType === 'twitterLink') {
                return `${record.socialMediaLinks.twitter}`;
            }

            if (tableMeta.columnType === 'contactNumbersMultiple') {
                return `${record.contactNumbers.join(' | ')}`;
            }

            if (tableMeta.columnType === 'documentsDisplayNameObject') {
                return (
                    record?.documents
                        ?.map((documentDisplayNameObject: { name: string }) => documentDisplayNameObject?.name)
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'dateAndTime') {
                return value?.split('.')[0].split('T').join(' ') || '--';
            }

            if (tableMeta.columnType === 'featureDisplayNameObject') {
                return (
                    record?.features
                        ?.map(
                            (featuresDisplayNameObject: { description: string }) =>
                                featuresDisplayNameObject?.description,
                        )
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'serviceAttributesDisplayNameObject') {
                return (
                    record?.serviceAttributes
                        ?.map(
                            (serviceAttributeDisplayNameObject: { name: string }) =>
                                serviceAttributeDisplayNameObject?.name,
                        )
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'plansDisplayNameObject') {
                return (
                    record?.plans
                        ?.map((planDisplayNameObject: { title: string }) => planDisplayNameObject?.title)
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'parametersDisplayNameObject') {
                return (
                    record?.parameters
                        ?.map((parametersDisplayNameObject: { name: string }) => parametersDisplayNameObject?.name)
                        ?.join(', ') || '--'
                );
            }

            if (tableMeta.columnType === 'serviceName') {
                return `${record?.service?.name || '--'}`;
            }

            if (tableMeta.columnType === 'categoryName') {
                return `${record?.category?.title || '--'}`;
            }

            if (tableMeta.columnType === 'titleName') {
                return `${record?.make?.title || '--'}`;
            }

            if (tableMeta.columnType === 'FuelTypeName') {
                // In that case the value will be undefined and hence the optional chaining
                return `${record?.fuelType?.join(', ') || '--'}`;
            }

            if (tableMeta.columnType === 'discountType') {
                // In that case the value will be undefined and hence the optional chaining
                const discountTypeTypeValue = record?.discountType || '--';
                return discountTypeTypeValue.charAt(0).toUpperCase() + discountTypeTypeValue.slice(1);
            }

            if (tableMeta.columnType === 'referralDiscountType') {
                // In that case the value will be undefined and hence the optional chaining
                const discountTypeTypeValue = record?.couponTarget || '--';
                return discountTypeTypeValue.charAt(0).toUpperCase() + discountTypeTypeValue.slice(1);
            }

            if (tableMeta.columnType === 'commissionType') {
                // In that case the value will be undefined and hence the optional chaining
                const commissionTypeValue = record?.commissionType || '--';
                return commissionTypeValue.charAt(0).toUpperCase() + commissionTypeValue.slice(1);
            }

            if (tableMeta.columnType === 'displayNameTitle') {
                return `${record?.title?.heading || '--'}`;
            }

            if (tableMeta.columnType === 'serviceName') {
                return `${record?.service?.name || '--'}`;
            }

            if (tableMeta.columnType === 'displayNameObjectArray') {
                return value
                    ?.map(
                        (displayNameObject: { name: string; mobile: string }) =>
                            `${displayNameObject?.name} - ${displayNameObject?.mobile}`,
                    )
                    .join(', ');
            }

            if (tableMeta.columnType === 'password') {
                return value?.password || '********';
            }

            if (tableMeta.columnType === 'clickHere') {
                return <RightCircleOutlined />;
            }

            if (tableMeta.columnType === 'date') {
                return moment.utc(value).toString();
            }

            if (tableMeta.columnType === 'dateAndTime') {
                return value?.split('.')[0].split('T').join(' ') || '--';
            }

            if (tableMeta.columnType === 'active') {
                return value ? 'Yes' : 'No';
            }

            if (tableMeta.columnType === 'image') {
                return (
                    <div>
                        {value && (
                            <img
                                src={value}
                                style={{ borderRadius: '50%', height: '40px', width: '40px', overflow: 'hidden' }}
                            />
                        )}
                    </div>
                );
            }

            if (tableMeta.columnType === 'dropDown') {
                return (
                    <select
                        onChange={props.onSelect}
                        name={tableMeta.name}
                        defaultValue={record.status}
                        style={{
                            border: '1px solid grey',
                            outline: 'none',
                            borderRadius: '5px',
                            padding: '2px',
                            cursor: 'pointer',
                        }}>
                        {tableMeta.option.map((o: any) => {
                            if (record.status === 'pickup-scheduled') {
                                return (
                                    <option value={o.value} key={o.value} className='custom-option'>
                                        {o.name}
                                    </option>
                                );
                            } else if (record.status === 'vehicle-in-transit' && o.value === 'vehicle-in-transit') {
                                return (
                                    <option
                                        value={o.value}
                                        key={o.value}
                                        className='custom-option'
                                        style={{
                                            border: 'none',
                                            background: 'inherit',
                                            outline: 'none',
                                            cursor: 'pointer',
                                            backgroundColor: o.value === record.status ? 'inherit' : 'inherit',
                                        }}>
                                        {o.name}
                                    </option>
                                );
                            } else if (record.status === 'vehicle-reached' && o.value === 'vehicle-reached') {
                                return (
                                    <option
                                        value={o.value}
                                        key={o.value}
                                        className='custom-option'
                                        style={{
                                            border: 'none',
                                            background: 'inherit',
                                            outline: 'none',
                                            cursor: 'pointer',
                                            backgroundColor: o.value === record.status ? 'inherit' : 'inherit',
                                        }}>
                                        {o.name}
                                    </option>
                                );
                            }
                        })}
                    </select>
                );
            }

            if (tableMeta.columnType === 'toggle' && entityName === PAYMENT_ENTITY) {
                return (
                    <Switch
                        style={{ backgroundColor: record.paymentStatus ? '#597ef7' : '#bfbfbf' }}
                        defaultChecked={record.paymentStatus}
                        checked={record.paymentStatus}
                        onChange={() => handleToggle(record?.paymentId)}
                    />
                );
            }
            if (tableMeta.columnType === 'toggle' && entityName === SERVICE_LIST_ENTITY) {
                return (
                    <Switch
                        style={{ backgroundColor: record.status ? '#597ef7' : '#bfbfbf' }}
                        defaultChecked={record.status}
                        checked={record.status}
                        onChange={() => handleToggle(record?.serviceDetailId)}
                    />
                );
            }

            return value ?? '--';
        },
    }));

    const getHeader = () => (
        <DefaultTableHeader
            headerName={props.headerName}
            totalCount={totalCount}
            countText={props.headerName === 'Experts' ? 'Profiles' : 'Items'}
            onAdd={() => props.onAdd && props.onAdd()}
            setSearchQuery={setSearchQuery}
            setPhoneSearchQuery={setPhoneSearchQuery}
            setCitySearchQuery={setCitySearchQuery}
            setStateSearchQuery={setStateSearchQuery}
            addName={props.addName ? props.addName : ''}
            disableAdd={props.disableAdd}
            disableSearch={disableSearch}
        />
    );

    return (
        <div className='landing-container'>
            {getHeader()}
            {/* This is the dropdown for filter in listing UI */}
            <div className='dropdown-group'>{getFilter()}</div>
            <Table
                columns={columns}
                loading={loading}
                dataSource={apiListingData}
                onChange={onPageChange}
                pagination={{ ...pagination, total: totalCount }}
                scroll={{ x: 'max-content' }}
                style={{ minHeight: '100vh' }}
                bordered
            />
            {showModal && (
                <ConfirmationModal
                    isOpen={showModal}
                    onclose={closeModal}
                    modalText={modalText}
                    handleConfirmation={handleConfirm}
                />
            )}
        </div>
    );
};

AbstractLanding.defaultProps = {
    refreshData: false,
};

export default AbstractLanding;
