/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Form, Input } from 'antd';

import { toastError, toastSuccess } from '../../service/Toast';
import { setToken } from '../../service/CookieManager';
import { updateTokensAction } from '../actions';
import callApi from '../../Api/config';

// import IzhalLogo from '../../assets/svg/Group.svg';
import { USERS_ENTITY } from '../../constants/EntityName';

import './styles.scss';

const LOGIN_URL = 'auth/signin';
// const LOGIN_URL = 'admin/login';
const Login = () => {
    const [loading, setLoading] = useState(false); // Add loading state
    const history = useHistory();
    const dispatch = useDispatch();

    const onFinish = async (incomingPayload: { email: string }) => {
        setLoading(true);
        const payload = { ...incomingPayload };

        const response: any = await callApi(LOGIN_URL, 'POST', payload);

        if (response?.data?.data) {
            const role = response?.data?.data?.userRole || 'Super Admin';
            // setRole(role);
            // const currentUserRole: any = getRole();
            // const rolePermission = RemoveRolePermission[currentUserRole];
            const token = response?.data?.data?.accessToken;
            setToken(token);
            dispatch(updateTokensAction(token));
            history.push(USERS_ENTITY);

            // if (role === Role.ACCOUNTANT) {
            //     history.push(PHOTO_ORDER_APPLICATION);
            // } else if (role === Role.ADMIN_AGENT) {
            //     history.push(CONTACT_FORM);
            // } else {
            //     history.push(USERS_ENTITY);
            // }

            toastSuccess('Login verified successfully');
            setLoading(false);
        } else {
            toastError(response?.data?.message);
            setLoading(false);
        }
    };

    return (
        <div className='container flex'>
            <div className='logo-container flex align-center justify-center'>
                <div className='flex align-center'></div>
            </div>
            <div className='form-container'>
                <div className='box'>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginRight: '40px',
                        }}>
                        {/* <img src={IzhalLogo} alt='Logo' style={{ width: '200px', height: '200px' }} /> */}
                    </div>
                    <h3 style={{ fontSize: '52px', fontWeight: '600' }}>Login to Dashboard</h3>
                    <Form
                        name='normal_login'
                        className='login-form'
                        layout='vertical'
                        initialValues={{ remember: true }}
                        onFinish={onFinish}>
                        <Form.Item
                            name='email'
                            label='Email'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Email Address!',
                                },
                            ]}>
                            <Input placeholder='Enter your Email' />
                        </Form.Item>

                        <Form.Item
                            name='password'
                            label='Enter your Password'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the valid Password here!',
                                },
                            ]}>
                            <Input placeholder='Enter the Password here' type='password' />
                        </Form.Item>
                        {/* <Form.Item>
                            <Link className='login-form-forgot' to={AppRoutes.ForgotPassword}>
                                Forgot Password
                            </Link>
                        </Form.Item> */}

                        {/* <Form.Item> */}
                        <Button loading={loading} type='primary' htmlType='submit' className='login-form-button' block>
                            submit
                        </Button>
                        {/* </Form.Item> */}
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default Login;
