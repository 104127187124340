import { customErrorMsg, errorMsg } from '../constants/messages';
import { REGEX_PHONE_NUMBER } from '../utils/Regexp/Regex';

type RolesType = { id: string; value: string; name: string }[];

export const rolesOption: RolesType = [
    { id: '1', value: 'Super Admin', name: 'Super Admin' },
    { id: '2', value: 'Admin Agent', name: 'Admin Agent' },
    { id: '3', value: 'Admin Ops', name: 'Admin Ops' },
];

const usersFormData = [
    {
        title: 'User Details',
        name: 'firstName',
        label: 'First Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'First Name', allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'lastName',
        label: 'Last Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Last Name' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'email',
        label: 'E-mail',
        fieldType: {
            name: 'email',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'E-mail' },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'phone',
        label: 'Phone No',
        fieldType: {
            name: 'text',
        },
        required: true,
        rules: [{ pattern: REGEX_PHONE_NUMBER, message: customErrorMsg('phone number') }],
        errorMsg: errorMsg,
        tableMeta: { title: 'Phone No', enableSort: false, allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'password',
        label: 'Password',
        fieldType: {
            name: 'text',
        },
        required: false,
        // rules: [{ pattern: REGEX_PASSWORD, message: customErrorMsg('password') }],
        errorMsg: errorMsg,
        tableMeta: { title: 'Password', columnType: 'password' },
        isTableHeader: false,
        isAccordian: true,
        default: '',
    },
    {
        name: 'roles',
        label: 'Role',
        fieldType: {
            name: 'select',
            options: [],
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Role', enableSort: false },
        isTableHeader: true,
        isAccordian: true,
        readonly: false,
        default: '',
    },
];

export { usersFormData };
