/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { useEffect, useState } from 'react';

import callApi from '../../Api/config';
import GenericAccordian from '../../components/GenericAccordian/GenericAccordian';
import { ACTIONS_ENTITY, MODULES_ENTITY, PERMISSIONS_ENTITY, ROLES_ENTITY } from '../../constants/EntityName';
import { errorMsg } from '../../constants/messages';
import { rolesFormData as initialRolesFormData } from '../../datamodels/Roles';
import { getFormDefaultValue, getTableHeaders } from '../../utils/forms/FormDataModel';
import { ContentItem, OnAddArguments, TabContent } from '../../utils/forms/FormInterface';
import AbstractForm from '../Entity/AbstractForm';
import AbstractLanding from '../Entity/AbstractLanding';
import AbstractTabs, { TabActiosEventType, TabActiosProps } from '../Entity/AbstractTabs';

const Roles = () => {
    const [activeKey, setActiveKey] = useState<string>('0');
    const [userName, setUserName] = useState<string>();
    const [showFormComponent, setShowFormComponent] = useState<boolean>(false);
    const [refreshData, setRefreshData] = useState<boolean>(false);
    const [content, setContent] = useState<TabContent>([]);
    const [rolesFormData, setRolesFormData] = useState<any>(initialRolesFormData);

    const defaultFormValue: any = getFormDefaultValue(rolesFormData);
    const tableHeaders: any = getTableHeaders(rolesFormData);
    const addName = 'Role';
    const headerName = 'Roles';

    /**
     * this useEffect fetches the different module names for roles and also the action names
     */
    // useEffect(() => {
    //     const getResponse = async () => {
    //         const roleModules = await callApi(MODULES_ENTITY, 'GET');
    //         const options = await callApi(ACTIONS_ENTITY, 'GET');
    //         console.log({ p: roleModules?.data });
    //         console.log({ o: options?.data });

    //         roleModules?.status &&
    //             options?.status &&
    //             setRolesFormData((rolesFormData: any) => [
    //                 ...rolesFormData,
    //                 // eslint-disable-next-line no-unsafe-optional-chaining
    //                 ...roleModules?.data?.map((singleInput: any, index: number) => {
    //                     // entry is a single input field in the form to create a new role
    //                     const entry = {
    //                         id: index,
    //                         name: singleInput.name,
    //                         label: singleInput.name,
    //                         fieldType: {
    //                             name: 'checkboxGroup',
    //                             options: options?.data?.map((option: { name: string }) => option.name),
    //                         },
    //                         errorMsg: errorMsg,
    //                         isTableHeader: false,
    //                         isAccordian: true,
    //                         default: [],
    //                     };

    //                     if (!index) return { title: 'Permissions', ...entry };

    //                     return entry;
    //                 }),
    //             ]);
    //     };

    //     getResponse();
    // }, []);

    const onSuccessful = (id?: string) => {
        if (id === 'create') {
            setShowFormComponent(false);
        }
        onTabClick('0');
        setRefreshData((prev) => !prev);
    };

    const onAddNew = (argument1?: OnAddArguments) => {
        if (argument1) {
            const checkArr = content.filter((item: ContentItem) => item.key === argument1.id);
            checkArr.length ? null : onAdd(argument1);
        } else {
            setShowFormComponent(true);
            setUserName('Create Role');
            onTabClick('a');
        }
    };

    /**
     * implements the functionality of closing a tab
     * @param key the key of the tab which we want to close
     * @param shouldRefresh whether the data on the landing page should be refetched? Should we true if a new data is added, modified or any data is deleted
     */
    const closeTab = (key: string, shouldRefresh: boolean) => {
        setContent((content) => content.filter((item) => item.key !== key));
        setActiveKey('0');
        shouldRefresh && setRefreshData((prev) => !prev);
        key === 'a' && setShowFormComponent(false);
    };

    const onAdd = (argument1: OnAddArguments) => {
        const newObj = {
            key: argument1.id,
            tabTitle: argument1.name,
            content: (
                <GenericAccordian key={content.length}>
                    <AbstractForm
                        entityId={argument1.id}
                        isCreateMode={false}
                        headerName={headerName}
                        entityName={ROLES_ENTITY}
                        formTemplate={rolesFormData}
                        formDefaultPayload={getFormDefaultValue(rolesFormData)}
                        onSuccessfulUpdate={onSuccessful}
                        additionalEntityName={PERMISSIONS_ENTITY}
                        formHeader=''
                        showDeleteDraft={true}
                        closeTab={closeTab}
                    />
                </GenericAccordian>
            ),
        };

        setContent((prev: any) => [...prev, newObj]);
        argument1.id && setActiveKey(String(argument1.id));
    };

    const onTabClick = (key: string) => {
        setActiveKey(key);
    };

    const updateFormVisibility = (action: TabActiosProps, targetKey?: string | TabActiosEventType) => {
        if (action === 'remove') {
            if (targetKey === 'a') {
                setShowFormComponent(false);
                onTabClick('0');
            } else {
                onTabClick('0');
                // whenever a tab is closed, that tab is filtered out from the list of tabs which are rendered inside the AbstractTab component
                setContent((content) => content.filter((item) => String(item.key) !== targetKey));
            }
        }
    };

    return (
        <AbstractTabs
            activeKey={activeKey}
            entityName='Role'
            updateFormVisibility={updateFormVisibility}
            onTabClick={onTabClick}
            secondTabTitle={userName}
            showFormComponent={showFormComponent}
            content={content}
            formComponent={
                <>
                    <GenericAccordian>
                        <AbstractForm
                            isCreateMode={true}
                            headerName={headerName}
                            entityName={ROLES_ENTITY}
                            formTemplate={rolesFormData}
                            formDefaultPayload={defaultFormValue}
                            onSuccessfulUpdate={onSuccessful}
                            additionalEntityName={PERMISSIONS_ENTITY}
                            closeTab={closeTab}
                        />
                    </GenericAccordian>
                </>
            }>
            <AbstractLanding
                headerName={headerName}
                addName={addName}
                entityName={ROLES_ENTITY}
                tableHeaders={tableHeaders}
                onAdd={onAddNew}
                refreshData={refreshData}
            />
        </AbstractTabs>
    );
};

export default Roles;
