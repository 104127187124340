import { errorMsg } from '../constants/messages';

const rolesFormData = [
    {
        title: 'Role Details',
        name: 'name',
        label: 'Name',
        fieldType: {
            name: 'text',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Name', enableSort: false, allowAddRecordClick: true },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'description',
        label: 'Description',
        fieldType: {
            name: 'textarea',
        },
        required: true,
        errorMsg: errorMsg,
        tableMeta: { title: 'Description', enableSort: false, allowAddRecordClick: false },
        isTableHeader: true,
        isAccordian: true,
        default: '',
    },
    {
        name: 'permissions',
        label: '',
        fieldType: {
            name: 'permissions',
        },
        errorMsg: errorMsg,
        tableMeta: { title: 'permissions', columnType: 'permissions' },
        isTableHeader: false,
        isAccordian: true,
        default: '',
    },
];

export { rolesFormData };
