export const ROLES_ENTITY = 'roles';
export const ACTIONS_ENTITY = 'actions';
export const MODULES_ENTITY = 'entitymodules';
export const PERMISSIONS_ENTITY = 'permissions';
export const SELLER_ENTITY = 'sellers';

export const DEFECT_TYPES_ENTITY = '/defect-types';
export const COMPLAINT_STATUS = '/complaint-status';
export const USERS_ENTITY = 'users';
export const FAQ_PAGE_ENTITY = '/documitra-content/faqs';
export const SERVICE_PAGE_ENTITY = '/documitra-content/services';
export const REVIEWS_PAGE_ENTITY = '/documitra-content/reviews';
export const ADVANTAGE_PAGE_ENTITY = '/documitra-content/advantages';
export const HOW_IT_WORKS_PAGE_ENTITY = '/documitra-content/how-it-works';
export const PHOTOMITRA_ADVANTAGE_PAGE_ENTITY = '/photomitra-content/advantages';
export const PHOTOMITRA_HOW_IT_WORKS_PAGE_ENTITY = '/photomitra-content/how-it-works';
export const PHOTOMITRA_TAKING_PHOTO_STEPS_PAGE_ENTITY = '/photomitra-content/taking-photo-steps';
export const PHOTO_STORE_PHOTO_SIZE = '/photo-store/photo-size';
export const PHOTO_STORE_FILE_TYPE = '/photo-store/file-type';
export const CONTACT_INFO = '/contact/contact-info';
export const CONTACT_FORM = '/contact/user-info';
export const APPLICATION_FORM_SERVICES = '/services';
export const APPLICATION_FORM_PARAMETERS = '/parameters';
export const APPLICATION_FORM_QUESTIONS = '/questions';
export const APPLICATION_FORM_PACKAGE_SERVICE_ATTRIBUTES = '/packages/service-attributes';
export const APPLICATION_FORM_PACKAGE_DOCUMENTS = '/packages/documents';
export const APPLICATION_FORM_PACKAGE_FEATURES = '/packages/features';
export const APPLICATION_FORM_PACKAGE_PLANS = '/packages/plans';
export const APPLICATION_FORM_PACKAGES = '/packages';
export const SERVICE_PROCESS_STEPS = '/service-process/process-steps';
export const SERVICE_PROCESS_DETAILS = '/service-process/process';
export const SERVICE_PROCESS_SUBTITLE = '/service-process/process/subtitle';

export const MAKES_ENTITY = '/admin/makes';
export const CITY_ENTITY = '/admin/cities';
export const MODELS_ENTITY = '/admin/models';
export const FUEL_TYPE_ENTITY = '/admin/fuel-types';
export const CATEGORY_CATEGORIES_ENTITY = '/category/categories';
export const CATEGORY_SUBCATEGORIES_ENTITY = '/category/sub-categories';
export const COUPONS_ENTITY = '/admin/coupons';
export const COMMISSION_LIST_ENTITY = '/admin/commissions';
export const COMMISSION_TYPE = '/garage/service/garages';
export const GARAGE_COMMISSION_TYPE = '/admin/garage-commissions';
export const REFERRAL_COUPONS_ENTITY = '/admin/referral-coupons';

export const CATEGORIES_ENTITY = '/categories';
export const SUBCATEGORIES_ENTITY = '/subcategories';
export const LOCATIONS_ENTITY = '/locations';
export const COMPLAINTS_ENTITY = '/complaints';
export const VARIANTS_ENTITY = '/variants';
export const ORDER_LIST_ENTITY = '/orders';
export const ALL_ORDER_LIST_ENTITY = '/admin/orders';
export const AVAILABLE_DELIVERY_PARTNERS = '/delivery-partners/available';
export const MANAGER_TECHNICIAN_ENTITY = '/manager-technician';
export const ALL_TECHNICIAN_ENTITY = '/users/technicians';
export const ALL_MANAGERS_ENTITY = '/users/managers';
export const ASSETS_ENTITY = '/assets';
export const LOCATION_TYPES_ENTITY = '/location-types';
export const PICK_UP_ENTITY = '/admin/third-parties';
export const GARAGE_MANAGEMENT_ENTITY = '/admin/garages';
export const USERS_LIST_ENTITY = '/admin/customers';
export const GUIDELINES_ENTITY = '/admin/guidelines';
export const PAYMENT_ENTITY = '/admin/payments';
export const THIRD_PARTY_CHARGES_ENTITY = '/admin/third-party-charges';
export const TOWING_CHARGES_ENTITY = '/admin/towing-vehicle-charges';
export const DASHBOARD = '/admin/dashboard';
export const SERVICE_LIST_ENTITY = '/admin/services';
export const SERVICE_CATEGORY_ENTITY = '/service/categories';
export const SERVICE_LEVEL_ENTITY = '/service/levels';
export const SERVICE_SUB_CATEGORY_ENTITY = '/service/sub-categories';
export const SERVICE_CAR_MODEL_ENTITY = '/service/makes';
export const GARAGE_BRANCH_NAME_ENTITY = '/garage/service/garages';
export const SERVICE_CREATE_ENTITY = '/service/services';
export const UPLOAD_PHOTO_ENTITY = 'photo/uploads';
